/**
 * Template Root
 */
import { Outlet } from 'react-router-dom';
import { UploadInvoice } from '../molecules/uploadInvoice/Upload';
import { useNoContractSelector } from '../organisms/actionDetails/noContractModal/Selector';
import { NoContractModal } from '../organisms/actionDetails/noContractModal/NoContractModal';
import { ToastResult } from '../molecules/toast/ToastResult';

const TemplateRoot: React.FC = () => {
  const { isShowModal, selected } = useNoContractSelector();

  return (
    <>
      <Outlet />
      <UploadInvoice />
      <ToastResult />
      {isShowModal && selected && <NoContractModal open={isShowModal} invoice={selected} />}
    </>
  );
};

export default TemplateRoot;
