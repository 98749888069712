import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { Sync as SyncIcon, Report as ReportIcon } from '@mui/icons-material';

import IconCheckImg from 'src/assets/images/icons/tick-circle-primary.svg';

import { INotificationCardStatusProps } from './types';

export const NotificationCardStatus: FC<INotificationCardStatusProps> = ({ status }) => {
  const label = useMemo(() => {
    if (status === 'EMAIL_RECEIVED') return 'Processing';
    if (status === 'BATCH_PROCESSED') return 'Completed';
    if (status === 'BATCH_PROCESSED_WITH_WARNINGS') return 'Completed With Warnings';
    return '';
  }, [status]);

  const icon = useMemo(() => {
    if (status === 'EMAIL_RECEIVED') return <SyncIcon className='text-[#A3A3A3] animate-spin' sx={{ fontSize: '16px' }} />;
    if (status === 'BATCH_PROCESSED') return <img src={IconCheckImg} alt='Up To Date' className='w-[16px]' />;
    if (status === 'BATCH_PROCESSED_WITH_WARNINGS') return <ReportIcon className='text-orange-30' sx={{ fontSize: '16px' }} />;
    return null;
  }, [status]);

  return (
    <div
      className={clsx(
        'h-24 min-w-[24px] rounded-full group/chip border',
        status === 'EMAIL_RECEIVED' && 'border-[#D9D9D9] text-[#A3A3A3] bg-white',
        status === 'BATCH_PROCESSED' && 'border-[#E0F3E6] text-primary bg-[#E0F3E6]',
        status === 'BATCH_PROCESSED_WITH_WARNINGS' && 'border-[#FEF3E5] bg-[#FEF3E5] text-orange-30'
      )}
    >
      <div className='p-1 flex items-center justify-center'>
        <div className='flex-1 overflow-hidden h-20 max-w-0 transition-[max-width] duration-500 group-hover/chip:max-w-[1000px]'>
          <div className='pr-6 pl-10 flex items-center'>
            <strong className='text-12 font-bold leading-20'>{label}</strong>
          </div>
        </div>
        <div className='w-20 h-20 flex items-center justify-center'>{icon}</div>
      </div>
    </div>
  );
};
