import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';
import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Sync as SyncIcon } from '@mui/icons-material';

import IconDocument from 'src/assets/images/icons/document.svg';
import IconArrowRight from 'src/assets/images/icons/chevron-right.svg';
import IconInfo from 'src/assets/images/icons/info-circle.svg';
import { CustomTooltip } from 'src/presentations/components/atom/customtooltip';
import { InvoiceFileProcessingStatusDto } from 'src/generated/services/TFinancialApi';
import authSelectors from 'src/infra/stores/reducer/auth/selectors';
import { COGNITO_GROUP } from 'src/infra/stores/reducer/auth/user';
import { beautifyEnum } from 'src/utils/utility';
import TransportMediumIcon from '../card/TransportMediumIcon';
import StatusChip from '../../organisms/dashboard/StatusChip';
import { useNoContractSelector } from '../../organisms/actionDetails/noContractModal/Selector';
import { useUploadSelector } from '../uploadInvoice/useUploadSelector';
import { simplifyFileNames } from '../uploadInvoice/tools';

const StyledChip = styled(Chip)({
  background: '#ffffff',
  border: '1px solid #D9D9D9',
  borderRadius: '20px',
  color: '#A3A3A3',
  height: '32px',
  paddingLeft: '4px',
  paddingRight: '4px',
  '& .MuiChip-label': {
    paddingRight: '0px!important',
  },
});

export const UploadStatusItem = ({ data }: { data: InvoiceFileProcessingStatusDto }) => {
  const role = useSelector(authSelectors.selectUserRole);
  const { showNoContract, setServiceProvider, setModality } = useNoContractSelector();
  const { onResetUpload, onCloseUpload } = useUploadSelector();

  const isErrorProcessing = useMemo(() => ['OCR_ERROR', 'TRANSFORM_ERROR', 'MESSAGE_PROCESSING_FAILED'].includes(data?.status), [data]);
  const isProcessing = useMemo(() => ['BEING_UPLOADED', 'OCR_PROCESSING', 'OCR_COMPLETE', 'TRANSFORM_COMPLETE'].includes(data?.status), [data]);

  const isButtonDisabled: boolean = useMemo(() => {
    let isDisabled = true;
    if (!isProcessing && data) {
      if (data?.isNoRateCardFound && role === COGNITO_GROUP.PROCUREMENT_MANAGER) {
        isDisabled = false;
      } else if (data?.invoiceId) {
        isDisabled = false;
      }
    }
    return isDisabled;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onShowNoRateCardFound = useCallback(() => {
    if (data?.invoiceId) {
      const invoiceData = {
        id: data.invoiceId,
        invoiceNumber: data.invoiceNumber,
        modality: data.modality,
        serviceProvider: data.serviceProviderCode,
      };

      showNoContract(invoiceData);
      setServiceProvider(data.serviceProviderCode);
      setModality(data.modality);

      onCloseUpload();
      onResetUpload();
    }
  }, [
    data.invoiceId,
    data.invoiceNumber,
    data.modality,
    data.serviceProviderCode,
    onCloseUpload,
    onResetUpload,
    setModality,
    setServiceProvider,
    showNoContract,
  ]);

  const btnStyles =
    'min-w-[125px] justify-center flex items-center gap-4 py-8 pl-16 pr-10 rounded-full text-neutral-70 font-medium text-14 border border-neutral-20 bg-white shadow-[0_2px_3px_0_rgba(0,0,0,0.1)]';

  return (
    <div className='flex items-center gap-16 py-16 px-24 rounded-8 bg-neutral-10'>
      <div className='w-36 relative'>
        <img src={IconDocument} alt='' />
        <span className='absolute -right-2 -bottom-2'>
          {isProcessing ? (
            <div className='rounded-full border border-[#597282] bg-[#EBEEF0] flex items-center justify-center'>
              <SyncIcon className='text-[#597282] animate-spin' sx={{ fontSize: '16px' }} />
            </div>
          ) : (
            <>
              {data?.modality !== null && data?.modality !== undefined && (
                <span className='absolute -right-2 -bottom-2'>
                  <TransportMediumIcon medium={data?.modality} iconColor='#9F9F9F' size='sm' />
                </span>
              )}
            </>
          )}
        </span>
      </div>
      <div className='flex-1 flex flex-col gap-2'>
        <div className='flex items-center gap-x-8'>
          {isProcessing ? (
            <div className='h-12 w-[76px] bg-[#DFDFDF] rounded-[3px]' />
          ) : (
            <span className='text-12 font-semibold'>{beautifyEnum(data?.serviceProviderCode)?.toUpperCase()}</span>
          )}
          <span className='rounded-full w-[5px] h-[5px] inline-block bg-[#D9D9D9]'>&nbsp;</span>
          <h3 className='text-14'>{simplifyFileNames(data.fileName)}</h3>
        </div>
        {isProcessing ? (
          <div className='h-12 w-[130px] bg-[#DFDFDF] rounded-[3px]' />
        ) : (
          <>
            {data?.modality && data?.invoiceNumber && (
              <p className='text-12 font-bold text-primary flex items-center gap-x-8'>
                <span className='text-neutral-60'>{data?.modality}</span>
                <span className='rounded-full w-[5px] h-[5px] inline-block bg-[#D9D9D9]'>&nbsp;</span>
                Inv No: {data?.invoiceNumber}
              </p>
            )}
          </>
        )}
        {isProcessing ? (
          <div className='mt-4 h-12 w-[210px] bg-[#DFDFDF] rounded-[3px]' />
        ) : (
          <p className='mt-2 text-12 text-neutral-60'>
            Updated on {format(new Date(data?.updatedOn), 'dd-MMM-yyyy')} at {format(new Date(data?.updatedOn), 'hh.mm a')}
          </p>
        )}
      </div>
      <div className='ml-auto flex items-center gap-24 justify-end'>
        <div className='flex items-center gap-14'>
          {!isProcessing && data?.isNoRateCardFound && <p className='text-12 text-neutral-60'>No Rate Card Found</p>}
          {!isProcessing && data?.invoiceStatus === 'REJECTED' && <p className='text-12 text-red-40 '>{beautifyEnum(data?.finalResolution)}</p>}
          {isErrorProcessing && (
            <>
              {!data?.fileResolutionStatus || data?.fileResolutionStatus === 'UNDER_REVIEW' || data?.fileResolutionStatus === 'FIXED' ? (
                <StyledChip
                  label={
                    <div className='flex items-center px-4 gap-8'>
                      <span className='font-medium'>Under Review</span>
                      <CustomTooltip title='Under investigation by the Support Team who will get in touch with you shortly'>
                        <img width={20} src={IconInfo} alt='' />
                      </CustomTooltip>
                    </div>
                  }
                  style={{
                    background: '#E2E6EC',
                    border: '1px solid #597282',
                    borderRadius: '20px',
                    color: '#597282',
                  }}
                  size='small'
                />
              ) : (
                <StatusChip status={data?.fileResolutionStatus} />
              )}
            </>
          )}
          {isProcessing && (
            <>
              <StyledChip
                label={
                  <div className='flex items-center px-4 gap-8 font-medium'>
                    <span>Processing</span>
                    <SyncIcon className='text-[#A3A3A3] animate-spin' sx={{ fontSize: '16px' }} />
                  </div>
                }
                style={{
                  background: '#ffffff',
                  border: '1px solid #D9D9D9',
                  borderRadius: '20px',
                  color: '#A3A3A3',
                }}
                size='small'
              />
            </>
          )}
          {!isErrorProcessing && !isProcessing && <StatusChip status={data?.invoiceStatus} />}
        </div>

        <div className='flex items-center justify-end'>
          {!isButtonDisabled ? (
            <>
              {data?.isNoRateCardFound ? (
                <button
                  type='button'
                  className={clsx(btnStyles, { 'opacity-40 cursor-not-allowed': role !== COGNITO_GROUP.PROCUREMENT_MANAGER })}
                  onClick={onShowNoRateCardFound}
                  disabled={role !== COGNITO_GROUP.PROCUREMENT_MANAGER}
                >
                  <span>Review</span>
                  <img src={IconArrowRight} alt='' />
                </button>
              ) : (
                <Link to={`/action-details/${data.invoiceId}?hideBack=1`} className={btnStyles} target='_blank'>
                  <span>See Invoice</span>
                  <img src={IconArrowRight} alt='' />
                </Link>
              )}
            </>
          ) : (
            <button type='button' disabled className={clsx(btnStyles, 'opacity-40 cursor-not-allowed')}>
              <span>{data?.isNoRateCardFound ? 'Review' : 'See Invoice'}</span>
              <img src={IconArrowRight} alt='' />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
